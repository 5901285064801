// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Dompurify = require("dompurify");

function sanitizedHTML(html) {
  return {
          __html: Dompurify.sanitize(html)
        };
}

function sanitizedHTMLOpt(html, options) {
  return {
          __html: Dompurify.sanitize(html, options)
        };
}

function sanitizedHTMLHook(entryPoint, hookFunction) {
  return Dompurify.addHook(entryPoint, hookFunction);
}

sanitizedHTMLHook('afterSanitizeAttributes', function(node) {
      // set all elements owning target to target=_blank
      if ('target' in node) {
          node.setAttribute('target','_blank');
          // prevent https://www.owasp.org/index.php/Reverse_Tabnabbing
          node.setAttribute('rel', 'noopener noreferrer');
      }
      // set non-HTML/MathML links to xlink:show=new
      if (!node.hasAttribute('target')
          && (node.hasAttribute('xlink:href')
              || node.hasAttribute('href'))) {
          node.setAttribute('xlink:show', 'new');
      }
  });
;

exports.sanitizedHTML = sanitizedHTML;
exports.sanitizedHTMLOpt = sanitizedHTMLOpt;
exports.sanitizedHTMLHook = sanitizedHTMLHook;
/*  Not a pure module */

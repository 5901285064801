// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var DOMPurify = require("./DOMPurify.bs.js");
var MarkdownIt = require("./markdownIt").default;

function parse(prim) {
  return MarkdownIt(prim);
}

function sanitize(html, profile) {
  if (profile) {
    return DOMPurify.sanitizedHTMLOpt(html, {
                ALLOWED_TAGS: [
                  "p",
                  "em",
                  "strong",
                  "del",
                  "s",
                  "a",
                  "sup",
                  "sub"
                ]
              });
  } else {
    return DOMPurify.sanitizedHTML(html);
  }
}

function toSafeHTML(markdown, profile) {
  return sanitize(MarkdownIt(markdown), profile);
}

exports.parse = parse;
exports.sanitize = sanitize;
exports.toSafeHTML = toSafeHTML;
/* DOMPurify Not a pure module */
